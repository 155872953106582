<template>
  <b-modal
    @shown="onShown()"
    :hide-header="true"
    :hide-footer="true"
    content-class="modal-blue"
    id="project-finance-modal"
    size="lg">
      <!-- Modal content-->
      <div class="row ">
        <div class="col mb-5 pb-5 ">
          <h4 class="mb-5">{{ $t('clubs.project.finances') }}</h4>
          <div class="registration-group-table mb-0">
            <sk-list v-if="loading" height="0.02" :items-count="4"></sk-list>
            <div v-else>
              <table class="table w-100">
                <tr>
                  <th>#</th>
                  <th>{{ $t('clubs.project.resource-description') }}</th>
                  <th>{{ $t('clubs.project.resource-qty') }}</th>
                  <th>{{ $t('clubs.project.resource-price') }}</th>
                  <th>{{ $t('clubs.project.sum') }}</th>
                  <th>&nbsp;</th>
                </tr>
                <tr v-for="(resource, i) of resources">
                  <td>{{ i + 1 }}</td>
                  <td>{{ resource.resurs_agwera }}</td>
                  <td>{{ resource.raodenoba }}</td>
                  <td>{{ resource.pasi }} (ლარი/GEL)</td>
                  <td>{{ resource.pasi * resource.raodenoba }} (ლარი/GEL)</td>
                  <td>
                    <img v-if="project.can_edit" class="cursor-pointer" @click="deleteResource(resource)" src="/static/images/icons/registrations/close.png">
                  </td>
                </tr>
                <tr v-if="project.can_edit">
                  <td>&nbsp;</td>
                  <td><input class="form-control" type="text" v-model="form.description"></td>
                  <td><input class="form-control" type="number" v-model="form.quantity"></td>
                  <td><input class="form-control" type="number" v-model="form.price"> (ლარი/GEL)</td>
                  <td><button class="btn btn-success" @click="addResource">{{ $t('clubs.project.add-resource') }}</button></td>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('clubs.project.close') }}</button>
        </div>
      </div>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';
  import ClubResource from '../../api/clubResource';

  const resource = new ClubResource()

  export default {
    name: 'project-finance-modal',
    components: { SkList },

    props: [ 'project' ],

    data() {
      return {
        loading: true,
        resources: [],
        form: this.emptyForm()
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
      })
    },

    methods: {
      addResource() {
        resource.createProjectResource(this.project.clubID, this.project.agwera_ID, this.form)
        .then(resp => {
          this.loadResources()
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
      },

      emptyForm() {
        return {
          description: '',
          quantity: '',
          price: ''
        }
      },

      deleteResource(r) {
        resource.deleteProjectResource(this.project.clubID, this.project.agwera_ID, r.ID)
        .then(resp => {
          this.loadResources()
        })
      },

      hideModal() {
        this.$bvModal.hide('project-finance-modal');
      },

      loadResources() {
        resource.projectResources(this.project.clubID, this.project.agwera_ID)
          .then(response => {
            this.resources = response.data.data
            this.loading = false
          })
      },

      onShown() {
        this.loadResources()
      }
    }
  };
</script>
