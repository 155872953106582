<template>
  <div class="row">
    <div class="col-12">
      <div class="bg-light p-5 b-30 shadow-1" >
        <template v-if="club">
          <table class="table-blue-wide w-100">
            <thead>
            <tr>
              <th>{{$t('clubs.club-name')}}</th>
              <th>{{$t('clubs.logo')}}</th>
              <th>{{$t('clubs.head-of-club')}}</th>
              <th>{{$t('clubs.phone')}}</th>
              <th>{{$t('clubs.mail')}}</th>
              <!--            <th>{{$t('clubs.projects')}}</th>-->
            </tr>
            </thead>
            <tbody>
            <tr class="cursor-pointer" @click="openForm">
              <td :data-label="$t('clubs.club-name')">{{ club.club_name }}</td>
              <td :data-label="$t('clubs.logo')">
                <img v-if="club.logo" :src="club.logo" alt="club-logo" class="img-fluid" style="max-width:5rem;">
              </td>
              <td :data-label="$t('clubs.head-of-club')">{{ helpers.getLocalizedField(club, 'fullname') }}</td>
              <td :data-label="$t('clubs.phone')">{{ club.mob }}</td>
              <td :data-label="$t('clubs.mail')">{{ club.email }}</td>
              <!--            <td :data-label="$t('clubs.projects')">414 GEL</td>-->
            </tr>
            </tbody>
          </table>
          <h3 v-if="club.adminComment" class="mt-2">{{ $t('clubs.comment') }}: {{ club.adminComment }}</h3>
          <projects v-if="club" :club="club"></projects>
        </template>
        <div class="text-center" v-if="canSendRequest">
          <div class="mb-5">{{ $t('clubs.youre-not-member') }}</div>
          <router-link to="/clubs/form" class="btn btn-primary-red">{{ $t('clubs.create-club') }}</router-link>
        </div>
        <div v-if="loading">
          <sk-list :items-count="2" :height="0.04"></sk-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClubResource from '../../api/clubResource';
import SkList from '../skeletons/sk-list';
import Projects from './projects';

const resource = new ClubResource()

export default {
  name: 'club-list',

  components: { Projects, SkList },

  data() {
    return {
      loading: false,
      club: null,
      canSendRequest: false,
    }
  },

  mounted() {
    this.loadClub()
  },

  methods: {
    openForm() {
      this.$router.push('/clubs/form')
    },

    loadClub() {
      this.loading = true;
      resource.clubs()
      .then(resp => {
        this.club = resp.data.data.myClub
        this.canSendRequest = resp.data.data.canSendRequest
      })
      .finally(() => {
        this.loading = false
      })
    }
  }
};
</script>
<style scoped>
.star-ic{
  max-width: 2rem;
}
</style>
