<template>
  <div>
    <div v-if="!parseInt(club.archive) && parseInt(club.appear)" class="row border-top border-bottom py-3">
      <div class="col-12 col-md-3 col-lg-2">
      
        <button v-if="this.club.studentID==this.$store.state.auth.user.studentID" @click="handleCreate()" class="btn btn-primary-red-7 w-100">{{ $t('clubs.project.create-project') }}</button>
      </div>

      <div class="col-12 col-md-9 col-lg-10 text-right">
              <span class="mr-4">
                <img src="/static/images/icons/evaluations/recover.png" class="img-fluid ic"> {{ $t('clubs.project.in-progress') }}
              </span>
        <span class="mr-4">
                <img src="/static/images/icons/evaluations/fail.png" class="img-fluid ic"> {{ $t('clubs.project.rejected') }}
              </span>
        <span class="mr-4">
                <img src="/static/images/icons/evaluations/accept.png" class="img-fluid ic"> {{ $t('clubs.project.accepted') }}
              </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <sk-list v-if="loading" :items-count="2" :height="0.04"></sk-list>
        
         
        <table v-if="!loading && projects.length > 0" class="table-blue-wide w-100">
          <thead>
          <tr>
            <th>{{$t('clubs.project.title')}}</th>
            <th>{{$t('clubs.project.price')}}</th>
            <th>{{$t('clubs.project.approved-price')}}</th>
            <th>{{$t('clubs.project.status')}}</th>
            <th>{{ $t('clubs.project.actions') }}</th>
          </tr>
          </thead>
        
          <tbody>
            
          <tr :key="$i" v-for="(project, $i) of projects">
           
            <td :data-label="$t('clubs.project.title')">{{ project.proj_name }}</td>
            <td :data-label="$t('clubs.project.price')">{{ project.pondidan_motxovna }}</td>
            <td :data-label="$t('clubs.project.approved-price')">{{ project.pondidan_damtkiceba }}</td>
            <td :data-label="$t('clubs.project.status')"><img class="img-fluid ic" :src="getStatusIcon(project.stat)" /></td>
            <td :data-label="$t('clubs.project.actions')" class="actions"  >
              <b-icon-info-circle v-if="project.can_edit" @click="handleEdit(project)" variant="info" :title="$t('clubs.project.details')"></b-icon-info-circle>
              <b-icon-cash-stack @click="handleFinances(project)" variant="success" :title="$t('clubs.project.finances')"></b-icon-cash-stack>
              <b-icon-chat-left-dots-fill v-if="project.comment"
                                          variant="warning"
                                          :title="$t('clubs.project.comment')+': '+project.comment"></b-icon-chat-left-dots-fill>
              <b-icon-x-circle-fill v-if="project.can_edit" @click="handleDelete(project)" variant="danger" :title="$t('clubs.project.delete')"></b-icon-x-circle-fill>
              
            </td>
          </tr>
          </tbody>
        </table>
        <project-finance-modal v-if="chosenProject" :project="chosenProject"></project-finance-modal>

        <div v-if="showForm" class="row">
          <div class="col-md-8 offset-md-2">
            <div class="row">
              <div class="col-12">
                <h3>{{ $t('clubs.project.form-title') }}</h3>
              </div>
              <div class="form-group col-12 mb-4">
                <label for="name">{{ $t('clubs.project.name') }}</label>
                <input v-model="form.name" id="name" type="text" class="form-control" />
              </div>
              <div class="col-12">
                {{ $t('clubs.project.rules', {student: helpers.getLocalizedField(user, 'fullName') }) }}
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="total_amount">{{ $t('clubs.project.total-amount') }} (ლარი/GEL)</label>
                <input v-model="form.total_amount" id="total_amount" type="text" class="form-control" />
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="student_count">{{ $t('clubs.project.student-count') }}</label>
                <input v-model="form.student_count" id="student_count" type="text" class="form-control" />
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="start_date">{{ $t('clubs.project.start-date') }}</label>
                <b-form-datepicker :date-format-options="datepickerFormat"
                                   id="start_date"
                                   v-model="form.start_date"
                                   :locale="locale"></b-form-datepicker>
<!--                <input v-model="form.start_date" id="start_date" type="text" class="form-control" />-->
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="start_time">{{ $t('clubs.project.start-time') }}</label>
                <b-form-timepicker id="start_time"
                                   :hour12="false"
                                   v-model="form.start_time"
                                   :locale="locale"></b-form-timepicker>
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="end_date">{{ $t('clubs.project.end-date') }}</label>
                <b-form-datepicker :date-format-options="datepickerFormat"
                                   id="end_date"
                                   v-model="form.end_date"
                                   :locale="locale"></b-form-datepicker>
              </div>
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="end_time">{{ $t('clubs.project.end-time') }}</label>
                <b-form-timepicker id="end_time"
                                   :hour12="false"
                                   v-model="form.end_time"
                                   :locale="locale"></b-form-timepicker>
              </div>
<!--              <div class="form-group col-12 col-md-6 mb-4">-->
<!--                <label for="end_date">{{ $t('clubs.project.end-date') }}</label>-->
<!--                <input v-model="form.end_date" id="end_date" type="text" class="form-control" />-->
<!--              </div>-->
              <div class="form-group col-12 col-md-6 mb-4">
                <label for="place">{{ $t('clubs.project.place') }}</label>
                <input v-model="form.place" id="place" type="text" class="form-control" />
              </div>
              <h3 class="col-12 text-center">პროექტის აღწერა</h3>
              <div class="form-group col-12">
                <label for="goals">{{ $t('clubs.project.goals') }}</label>
                <textarea v-model="form.goal" id="goals" type="text" class="form-control" />
              </div>
              <div class="form-group col-12">
                <label for="description">{{ $t('clubs.project.description') }}</label>
                <textarea v-model="form.description" id="description" type="text" class="form-control" />
              </div>
              <div class="form-group col-12">
                <label for="plan">{{ $t('clubs.project.plan') }}</label>
                <textarea v-model="form.plan" id="plan" type="text" class="form-control" />
              </div>
              <div class="form-group col-12">
                <label for="results">{{ $t('clubs.project.results') }}</label>
                <textarea v-model="form.results" id="results" type="text" class="form-control" />
              </div>
              <div class="form-group col-12">
                <label for="organizers">{{ $t('clubs.project.organizers') }}</label>
                <textarea v-model="form.organizers" id="organizers" type="text" class="form-control" />
              </div>
              <div class="form-group col-12 text-center">
                <button @click="handleSave" class="btn btn-danger" :disabled="btnDisabled">{{ $t('clubs.project.save') }}</button>
                <button @click="handleCancel" class="btn btn-secondary">{{ $t('clubs.project.cancel') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkList from '../skeletons/sk-list';
import { BIcon, BIconInfoCircle, BIconCashStack, BIconChatLeftDotsFill, BIconXCircleFill } from 'bootstrap-vue'
import ClubResource from '../../api/clubResource';
import { mapGetters } from 'vuex';
import ProjectFinanceModal from './project-finance-modal';

const resource = new ClubResource()

export default {
  name: 'projects',

  props: ['club'],

  components: { ProjectFinanceModal, BIcon, BIconInfoCircle, BIconCashStack, BIconChatLeftDotsFill, BIconXCircleFill, SkList },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      locale: 'language/locale'
    })
  },

  data() {
    return {
      datepickerFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
      form: null,
      projects: [],
      showForm: false,
      loading: false,
      chosenProject: null,
      btnDisabled: false
    }
  },

  mounted() {
    this.loadProjects()
  },

  methods: {
    handleSave() {
      this.form.start_date = this.form.start_date.substring(0, 10) + ' ' + this.form.start_time.substring(0, 5)
      this.form.end_date = this.form.end_date.substring(0, 10) + ' ' + this.form.end_time.substring(0, 5)

      if (this.form.id) this.update()
      else this.create()
    },

    update() {
      this.btnDisabled = true
      resource.updateProject(this.club.self_governmentID, this.form.id, this.form)
      .then(response => {
        this.chosenProject = null
        // this.loadProjects()
        location.reload()
      })
      .catch(err => {
        this.helpers.notifyErrorMessage(err)
      })
      .finally(() => {
        this.btnDisabled = false
      })
    },

    create() {
      this.btnDisabled = true
      resource.createProject(this.club.self_governmentID, this.form)
        .then(response => {
          this.chosenProject = null
          //this.loadProjects()
          location.reload()
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
        .finally(() => {
          this.btnDisabled = false
        })
    },

    handleCreate() {
      this.form = this.emptyForm()
      this.showForm = true
    },

    handleCancel() {
      this.form = this.emptyForm()
      this.showForm = false
    },

    handleEdit(project) {

        if(this.club.studentID==this.$store.state.auth.user.studentID){

      resource.projectDetails(this.club.self_governmentID, project.agwera_ID)
      .then(resp => {
        this.fillForm(resp.data.data);
        this.showForm = true
      })
    }else {

      alert("არ გაქვთ უფლება")

    }
    },

    handleFinances(project) {

      if(this.club.studentID==this.$store.state.auth.user.studentID){
      this.chosenProject = project

      setTimeout(() => {
        this.$bvModal.show('project-finance-modal')
      }, 300)
    }else {

alert("არ გაქვთ უფლება")

}
    },

    handleDelete(project) {

      if(this.club.studentID==this.$store.state.auth.user.studentID){
      this.$swal({
        title: "",
        text: this.$t('clubs.project.confirm-delete'),
        type: "warning",
        showCancelButton: true,
        closeOnConfirm: true,
        closeOnCancel: true
      }).then((result) => {
        if(result && result.isConfirmed) {
          resource.deleteProject(this.club.self_governmentID, project.agwera_ID)
            .then(r => {
              this.loadProjects()
            })
            .catch(err => {
              this.helpers.notifyErrorMessage(err)
            })
        }
      });
    }else {

alert("არ გაქვთ უფლება")

}

    },

    getStatusIcon(stat) {
      if (parseInt(stat) === 0) return '/static/images/icons/evaluations/recover.png';
      else if (parseInt(stat) === 1) return '/static/images/icons/evaluations/accept.png';
      else return '/static/images/icons/evaluations/fail.png';
    },

    loadProjects() {
      resource.projects(this.club.self_governmentID)
      .then(resp => {
        this.projects = resp.data.data
       
      })
    },

    fillForm(data) {
      this.form = {
        id: data.agwera_ID,
        name: data.proj_name,
        total_amount: data.pondidan_motxovna,
        start_date: data.proj_start.substring(0, 10),
        end_date: data.proj_end.substring(0, 10),
        start_time: data.dro + ':00',
        end_time: data.dro_end + ':00',
        student_count: data.studcount,
        place: data.adgili,
        is_online: '',
        goal: data.proj_mizani,
        description: data.proj_rezume,
        plan: data.proj_gegma,
        results: data.proj_shedegi,
        organizers: data.proj_group
      }
    },

    emptyForm() {
      return {
        id: '',
        name: '',
        total_amount: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        student_count: '',
        place: '',
        is_online: '',
        goal: '',
        description: '',
        plan: '',
        results: '',
        organizers: ''
      }
    }
  }
};
</script>

<style scoped>

@import '~bootstrap-vue/dist/bootstrap-vue.min.css';
  .actions > * {
    font-size: 24px;
    margin-left: 20px;
    cursor: pointer;
  }
</style>
